<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xs"
    scrollable
  >
    <v-card>
      <v-card-title
        class="pt-3"
        style="padding-bottom: 7.5px"
      >
        Detalle liquidación {{ $vuetify.breakpoint.smAndUp ? `Nº ${p_liqui.num_liqui} - Periodo ${p_periodo}` : '' }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <!-- TITLE DE LOS HEADERS -->
      <v-card-title class="pl-3 pl-md-6 pb-0">
        <v-row>
          <template v-if="$vuetify.breakpoint.xs">
            <v-col cols="6" class="pb-0 pr-0">
              <v-text-field
                v-model.trim="p_liqui.num_liqui"
                label="Número"
                type="number"
                hide-details
                outlined
                readonly
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pb-0 pr-0">
              <v-text-field
                v-model.trim="p_periodo"
                label="Periodo"
                hide-details
                outlined
                readonly
                filled
                dense
              ></v-text-field>
            </v-col>
          </template>
          <v-col cols="12" sm="6" md="4" xl="3" class="pb-0 mr-md-3">
            <v-row>
              <v-col cols="3" class="pr-0">
                <v-text-field
                  v-model.trim="p_liqui.supervisor_codigo"
                  label="Código"
                  type="number"
                  hide-details
                  outlined
                  readonly
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col class="pr-0">
                <v-text-field
                  v-model.trim="p_liqui.supervisor_nombre"
                  label="Supervisor"
                  hide-details
                  outlined
                  readonly
                  filled
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" md="4" xl="3">
            <v-row>
              <v-col cols="3" class="pr-0">
                <v-text-field
                  v-model.trim="p_liqui.ivr"
                  label="IVR"
                  type="number"
                  hide-details
                  outlined
                  readonly
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col class="pr-0">
                <v-text-field
                  v-model.trim="p_liqui.ptovta_nombre"
                  label="Punto de venta"
                  hide-details
                  outlined
                  readonly
                  filled
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-col cols="12" class="pb-0 pl-md-0 pr-0">
          <v-data-table
            v-if="$vuetify.breakpoint.smAndUp"
            class="elevation-2"
            :items="[p_liqui]"
            :headers="headers"
            hide-default-footer
            dense
          >
            <!-- template para formatear montos -->
            <template
              v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
              v-slot:[`item.${header.value}`]="{ value }"
            >
              {{ header.formatter(value) }}
            </template>
          </v-data-table>
          <!-- VISTA PARA CELULARES -->
          <template v-else>
            <v-row>
              <v-col cols="4" class="pb-0 pl-0 pr-1">
                <v-text-field
                  v-model.trim="p_liqui.prepagos"
                  label="Prepagos"
                  type="number"
                  prefix="$"
                  hide-details
                  outlined
                  readonly
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pb-0 px-1">
                <v-text-field
                  v-model.trim="p_liqui.internet"
                  label="Internet"
                  type="number"
                  prefix="$"
                  hide-details
                  outlined
                  readonly
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pb-0 pl-1 pr-0">
                <v-text-field
                  v-model.trim="p_liqui.otros"
                  label="Otros"
                  type="number"
                  prefix="$"
                  hide-details
                  outlined
                  readonly
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pb-0 pl-0 pr-1">
                <v-text-field
                  v-model.trim="p_liqui.subtotal"
                  label="Subtotal"
                  type="number"
                  prefix="$"
                  hide-details
                  outlined
                  readonly
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pb-0 px-1">
                <v-text-field
                  v-model.trim="p_liqui.iva"
                  label="IVA"
                  type="number"
                  prefix="$"
                  hide-details
                  outlined
                  readonly
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pb-0 pl-1 pr-0">
                <v-text-field
                  v-model.trim="p_liqui.total"
                  label="Total"
                  type="number"
                  prefix="$"
                  hide-details
                  outlined
                  readonly
                  filled
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
        </v-col>
        <!-- PESTAÑAS -->
        <v-col class="px-0 pb-0">
          <v-tabs
            v-model="tab"
          >
            <v-tab>Prepagos</v-tab>
            <v-tab>Internet</v-tab>
            <v-tab>Otros</v-tab>
          </v-tabs>
        </v-col>
      </v-card-title>
      <v-card-text class="px-0">
        <v-tabs-items v-model="tab" class="px-4 pt-3 pb-1">
          <!-- DETALLE PREPAGOS -->
          <v-tab-item>
            <v-data-table
              class="elevation-2 cebra"
              :items="prepagos"
              :headers="prepagos_headers"
              dense
            >
              <!-- template para formatear montos -->
              <template
                v-for="header in prepagos_headers.filter((header) => header.hasOwnProperty('formatter'))"
                v-slot:[`item.${header.value}`]="{ value }"
              >
                {{ header.formatter(value) }}
              </template>
              <!-- no data -->
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  max-width="400"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  No hay datos para el detalle seleccionado
                </v-alert>
              </template>
            </v-data-table>
          </v-tab-item>
          <!-- DETALLE INTERNET -->
          <v-tab-item>
            <v-data-table
              class="elevation-2 cebra"
              :items="internet"
              :headers="internet_headers"
              dense
            >
              <!-- template para formatear montos -->
              <template
                v-for="header in internet_headers.filter((header) => header.hasOwnProperty('formatter'))"
                v-slot:[`item.${header.value}`]="{ value }"
              >
                {{ header.formatter(value) }}
              </template>
              <!-- no data -->
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  max-width="400"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  No hay datos para el detalle seleccionado
                </v-alert>
              </template>
            </v-data-table>
          </v-tab-item>
          <!-- DETALLE OTROS -->
          <v-tab-item>
            <v-data-table
              class="elevation-2 cebra"
              :items="otros"
              :headers="otros_headers"
              dense
            >
              <!-- filtro -->
              <template v-slot:top>
                <v-row class="d-flex justify-end align-center pb-2 px-3 pt-4">
                  <v-col cols="7" sm="6" md="3" xl="2">
                    <v-autocomplete
                      v-model="tipo_producto"
                      label="Tipo producto"
                      item-value="codigo"
                      item-text="nombre"
                      :items="tipos_productos"
                      hide-details
                      clearable
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-btn
                    color="info"
                    class="mr-4"
                    small
                    @click="filtrar()"
                  >
                    <v-icon left>fas fa-filter</v-icon>
                    Filtrar
                  </v-btn>
                </v-row>
              </template>
              <!-- template para formatear montos -->
              <template
                v-for="header in otros_headers.filter((header) => header.hasOwnProperty('formatter'))"
                v-slot:[`item.${header.value}`]="{ value }"
              >
                {{ header.formatter(value) }}
              </template>
              <!-- ultima row para el total -->
              <template slot="body.append">
                <tr class="font-weight-bold">
                  <th style="font-size: 14px">Total</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th style="font-size: 14px" class="text-right">
                    {{ formatMoney(otros_totales) }}
                  </th>
                  <th></th>
                </tr>
              </template>
              <!-- no data -->
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  max-width="400"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  No hay datos para los filtros seleccionados
                </v-alert>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <!-- BOTONES -->
      <v-card-actions class="d-flex justify-end">
        <v-btn
          color="secondary"
          @click="dialog = false"
        >
          Volver
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { format_money } from '../../util/utils'

export default {
  data () {
    return {
      formatMoney: format_money,
      tab: null,
      tipo_producto: null,
      otros_totales: 0,
      prepagos: [],
      internet: [],
      otros: [],
      headers: [
        { text: 'Prepagos', value: 'prepagos', align: 'end', sortable: false, formatter: format_money },
        { text: 'Internet', value: 'internet', align: 'end', sortable: false, formatter: format_money },
        { text: 'Otros', value: 'otros', align: 'end', sortable: false, divider: true, formatter: format_money },
        { text: 'Subtotal', value: 'subtotal', align: 'end', sortable: false, formatter: format_money },
        { text: 'IVA (10%)', value: 'iva', align: 'end', sortable: false, divider: true, formatter: format_money },
        { text: 'Total', value: 'total', align: 'end', sortable: false, formatter: format_money },
      ],
      prepagos_headers: [
        { text: 'Mes', value: 'mes' },
        { text: 'Línea', value: 'linea', align: 'end' },
        { text: 'Contrato', value: 'contrato' },
        { text: 'Activación', value: 'fecha_activacion' },
        { text: 'Aprobación', value: 'fecha_aprobacion' },
        { text: 'Estado', value: 'estado' },
        { text: 'Com. carga inic.', value: 'comision_carga_inicial', align: 'end', formatter: format_money },
        { text: 'Monto', value: 'monto', align: 'end', formatter: format_money },
        { text: 'Com. recarga', value: 'comision_recarga', align: 'end', formatter: format_money },
        { text: 'Bonif. porta.', value: 'bonificacion_portabilidad', align: 'end', formatter: format_money },
        { text: 'Total', value: 'total', align: 'end', formatter: format_money }
      ],
      internet_headers: [
        { text: 'Línea', value: 'linea', align: 'end' },
        { text: 'Descripción producto', value: 'descripcion' },
        { text: 'Activación', value: 'fecha_activacion' },
        { text: 'Aprobación', value: 'fecha_aprobacion' },
        { text: 'Comisión', value: 'comision', align: 'end', formatter: format_money }
      ],
      otros_headers: [
        { text: 'Línea', value: 'linea', align: 'end' },
        { text: 'Concepto', value: 'concepto' },
        { text: 'Cantidad', value: 'cantidad', align: 'end' },
        { text: 'Importe', value: 'importe', align: 'end', formatter: format_money },
        { text: 'Total', value: 'total', align: 'end', formatter: format_money },
        { text: 'Tipo producto', value: 'tipo_producto_nombre' }
      ]
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('grupopy/get_tipos_productos')
    this.$store.state.loading = false
  },
  props: {
    value: Boolean,
    p_periodo: String,
    p_liqui: Object
  },
  computed: {
    ...mapState('grupopy', ['tipos_productos']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    dialog (val) {
      if (val) {
        this.tab = null
        this.tipo_producto = null
        this.otros_totales = JSON.parse(JSON.stringify(this.p_liqui.otros))
        this.prepagos = JSON.parse(JSON.stringify(this.p_liqui.arr_prepagos))
        this.internet = JSON.parse(JSON.stringify(this.p_liqui.arr_internet))
        this.otros = JSON.parse(JSON.stringify(this.p_liqui.arr_otros))
      }
    }
  },
  methods: {
    filtrar () {
      if (this.tipo_producto) {
        this.otros = JSON.parse(JSON.stringify(this.p_liqui.arr_otros))
        this.otros = this.otros.filter(o => o.tipo_producto_codigo == this.tipo_producto)
        this.otros_totales = 0
        this.otros.forEach(otros => {
          this.otros_totales += otros.total
        })
      }
      else {
        this.otros = JSON.parse(JSON.stringify(this.p_liqui.arr_otros))
        this.otros_totales = JSON.parse(JSON.stringify(this.p_liqui.otros))
      }
    }
  }
}
</script>